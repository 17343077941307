/*
 * Copyright (C) 2022 Kacper Donat
 *
 * @author Kacper Donat <kacper@kadet.net>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import { inject, ref } from "vue";
import { ApiClientKey } from "@/api";
import { EndpointParams, EndpointResult, Endpoints } from "@/api/endpoints";
import { BoundRequestOptions } from "@/api/client";

export type FetchStatus = "fetching" | "ready" | "error";

export default function useDataFromEndpoint<TEndpoint extends keyof Endpoints>(
    endpoint: TEndpoint,
    options: BoundRequestOptions<EndpointParams<Endpoints, TEndpoint>, "provider">
) {
    const client = inject(ApiClientKey);

    const data = ref<EndpointResult<Endpoints, TEndpoint>>();
    const error = ref();
    const status = ref<FetchStatus>("fetching");

    async function refresh() {
        status.value = "fetching";
        const result = await client.get(endpoint, options);

        if (result.status === 200) {
            status.value = "ready";
            data.value = result.data;
        } else {
            status.value = "error";
            error.value = result.data;
        }
    }

    void refresh();

    return { data, status, error, refresh };
}
